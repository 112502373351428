import { useState, useRef, useCallback } from "react";
import { Menu, X, ChevronDown, ArrowRight } from "lucide-react";
import {
  convertTitleToUrl,
  getContrastColor,
  handleNavigation,
} from "../../Utils/functions";
import FloatingActionButton from "./FloatingActionButton";
import Logo from "../../assets/Logos/MT_Logo_Horizontal_white.png";
import { motion, AnimatePresence } from "framer-motion";
import { useProducts } from "../../Providers/ProductProvider";
import { useApplications } from "../../Providers/ApplicationProvider";

type NavItem = {
  label: string;
  href: string;
  children?: NavItem[];
};

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { products } = useProducts();
  const { applications } = useApplications();

  const navItems: NavItem[] = [
    {
      label: "Products",
      href: "/products",
      children: [
        { label: "All Products", href: "/products" },
        ...products.map((product) => ({
          label: product.name,
          href: `/products/${convertTitleToUrl(product.name)}`,
          abbreviation: product.abbreviation,
          color: product.color,
        })),
      ],
    },
    {
      label: "Applications",
      href: "/applications",
      children: [
        { label: "All Applications", href: "/applications" },
        ...applications.map((application) => ({
          label: application.name,
          href: `/applications/${convertTitleToUrl(application.name)}`,
        })),
      ],
    },
    {
      label: "Resources",
      href: "/resources",
      children: [
        { label: "Our Resources", href: "/resources" },
        {
          label: "Catalog",
          href: "https://modtruss.com/ModTruss_Catalog.pdf",
        },
        {
          label: "Connection Guide",
          href: "https://modtruss.com/ModTruss_Connection_Guide.pdf",
        },
        // {
        //   label: 'Truss - 3" Steel Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_03_st_tr_loadtables.pdf",
        // },
        // {
        //   label: 'Truss - 6" Aluminum Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_al_tr_loadtables.pdf",
        // },
        // {
        //   label: 'Truss - 6" Steel Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_06_st_tr_loadtables.pdf",
        // },
        // {
        //   label: 'Truss - 12" Aluminum Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_6.13.19_12_al_tr_loadtables.pdf",
        // },
        // {
        //   label: 'Truss - 12" Steel Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_12_st_tr_loadtables.pdf",
        // },
        // {
        //   label: 'Extrusion - 3"X3" Aluminum Load Table',
        //   href: "https://files.modtruss.com/files/tech.catalog_v6_3.28.19_03_ex_loadtables.pdf",
        // },
        // {
        //   label: 'Extrusion - 6"X6" Aluminum Load Table',
        //   href: "https://files.modtruss.com/files/6_x6_-aluminum-extrusion-load-table-updated.pdf",
        // },
        // {
        //   label: 'Extrusion - 6"X12" Aluminum Load Table',
        //   href: "https://files.modtruss.com/files/6_x12_-aluminum-extrusion-load-table.pdf",
        // },
        {
          label: "Load Tables",
          href: "/load-tables",
        },
        {
          label: "SketchUp 3D Warehouse",
          href: "https://3dwarehouse.sketchup.com/by/ModTruss",
        },
        { label: "Warranty & Policies", href: "/warranty-and-policies" },
        { label: "Financing", href: "https://milestonebank.com/" },
      ],
    },
    {
      label: "About",
      href: "/about",
      children: [
        { label: "About Us", href: "/about" },
        { label: "Leadership", href: "/leadership" },
        { label: "Contact Us", href: "/contact-us" },
      ],
    },
    {
      label: "Catalog",
      href: "https://modtruss.com/ModTruss_Catalog.pdf",
    },
    // {
    //   label: "Manage",
    //   href: "/manage",
    // },
  ];

  const handleDropdownHover = useCallback((label: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setActiveDropdown(label);
  }, []);

  const handleDropdownLeave = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setActiveDropdown(null);
    }, 150);
  }, []);

  const handleNavigationLocal = (href: string) => {
    setIsMobileMenuOpen(false);
    handleNavigation(href);
  };

  const ProductMenuItem = ({ item }: { item: any }) => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleNavigation(item.href);
        setActiveDropdown(null);
      }}
      className="block w-full text-left px-3 sm:px-4 py-2.5 sm:py-3 text-sm text-gray-300 
                 hover:text-white hover:bg-gray-700 transition-colors"
    >
      <div className="flex items-center gap-2 sm:gap-3">
        {item.abbreviation && (
          <div
            className={`w-5 h-5 sm:w-6 sm:h-6 flex items-center justify-center text-xs font-medium border-[.5px] border-gray-500 
                       ${
                         item.color
                           ? getContrastColor(item.color)
                           : "text-white"
                       }`}
            style={{ backgroundColor: item.color || "#666" }}
          >
            {item.abbreviation}
          </div>
        )}
        <span>{item.label}</span>
      </div>
    </button>
  );

  const MobileProductMenuItem = ({ item }: { item: any }) => (
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleNavigationLocal(item.href);
      }}
      className="block w-full text-left px-4 py-2.5 text-sm text-gray-400 
                 hover:text-white transition-colors"
    >
      <div className="flex items-center gap-2">
        {item.abbreviation && (
          <div
            className={`w-5 h-5 flex items-center justify-center text-xs font-medium border-[.5px] border-gray-500 
                       ${
                         item.color
                           ? getContrastColor(item.color)
                           : "text-white"
                       }`}
            style={{ backgroundColor: item.color || "#666" }}
          >
            {item.abbreviation}
          </div>
        )}
        <span>{item.label}</span>
      </div>
    </button>
  );

  return (
    <nav className="fixed w-full z-50 bg-brand text-white">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between items-center h-16 sm:h-20 md:h-24">
          {/* Logo */}
          <div className="flex items-center h-full">
            <button
              onClick={() => handleNavigationLocal("/")}
              className="flex items-center h-full w-36 sm:w-40 md:w-48 justify-center relative"
            >
              <img
                src={Logo}
                alt="ModTruss Logo"
                className="w-auto h-8 sm:h-10 md:h-12"
              />
            </button>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-0.5 lg:gap-1">
            {navItems.map((item) => (
              <div
                key={item.label}
                className="relative group"
                onMouseEnter={() => handleDropdownHover(item.label)}
                onMouseLeave={handleDropdownLeave}
              >
                <button
                  onClick={() => handleNavigationLocal(item.href)}
                  className="px-3 lg:px-4 py-2 text-sm lg:text-base text-gray-300 hover:text-white 
                    flex items-center gap-1 transition-colors duration-200"
                >
                  {item.label}
                  {item.children && (
                    <ChevronDown
                      className={`w-3.5 h-3.5 lg:w-4 lg:h-4 transition-transform duration-200 
                      ${activeDropdown === item.label ? "rotate-180" : ""}`}
                    />
                  )}
                </button>

                <AnimatePresence>
                  {item.children && activeDropdown === item.label && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute left-0 mt-1 w-48 lg:w-56 bg-gray-800 border border-gray-700 z-50"
                      onMouseEnter={() => handleDropdownHover(item.label)}
                      onMouseLeave={handleDropdownLeave}
                    >
                      {item.children.map((child) => (
                        <ProductMenuItem key={child.label} item={child} />
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
            <button
              className="ml-3 lg:ml-4 mr-4 lg:mr-6 group bg-brand-accent text-brand-accentText 
                px-4 lg:px-6 py-2 lg:py-2.5 text-sm lg:text-base hover:bg-brand-accentHover 
                transition-all duration-200 flex items-center gap-1.5 lg:gap-2"
              onClick={() => handleNavigationLocal("/contact-us")}
            >
              LET'S CONNECT
              <ArrowRight
                className="w-3.5 h-3.5 lg:w-4 lg:h-4 transition-transform duration-200 
                group-hover:translate-x-1"
              />
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden pr-4 sm:pr-6">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-1 text-gray-300 hover:text-white transition-colors"
            >
              {isMobileMenuOpen ? (
                <X className="h-5 w-5 sm:h-6 sm:w-6" />
              ) : (
                <Menu className="h-5 w-5 sm:h-6 sm:w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 md:hidden"
          >
            {/* Overlay */}
            <div
              className="fixed inset-0 bg-black/50"
              onClick={() => setIsMobileMenuOpen(false)}
            />

            {/* Menu Content */}
            <motion.div
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "tween", duration: 0.3 }}
              className="absolute right-0 top-0 bottom-0 w-full max-w-[280px] sm:max-w-sm 
                bg-gray-800 flex flex-col shadow-xl"
            >
              {/* Menu Header */}
              <div className="flex items-center justify-between p-3 sm:p-4 border-b border-gray-700">
                <img
                  src={Logo}
                  alt="ModTruss Logo"
                  className="h-6 sm:h-8 w-auto"
                  onClick={() => handleNavigationLocal("/")}
                />
                <button
                  onClick={() => setIsMobileMenuOpen(false)}
                  className="p-1.5 sm:p-2 text-gray-300 hover:text-white transition-colors"
                >
                  <X className="h-5 w-5 sm:h-6 sm:w-6" />
                </button>
              </div>

              {/* Scrollable Menu Items */}
              <div className="flex-1 overflow-y-auto">
                <div className="py-1 sm:py-2">
                  {navItems.map((item) => (
                    <div
                      key={item.label}
                      className="border-b border-gray-700 last:border-0"
                    >
                      <button
                        onClick={() => {
                          handleNavigationLocal(item.href);
                          setIsMobileMenuOpen(false);
                        }}
                        className="block w-full text-left px-4 py-2.5 sm:py-3 text-gray-300 
                          hover:text-white transition-colors font-medium text-sm sm:text-base"
                      >
                        {item.label}
                      </button>
                      {item.children && (
                        <div className="pl-4 bg-gray-900 py-1.5 sm:py-2">
                          {item.children.map((child) => (
                            <MobileProductMenuItem
                              key={child.label}
                              item={{
                                ...child,
                                onClick: () => {
                                  handleNavigationLocal(child.href);
                                  setIsMobileMenuOpen(false);
                                },
                              }}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {/* Fixed Bottom Button */}
              <div className="p-3 sm:p-4 border-t border-gray-700 bg-gray-800">
                <button
                  className="w-full bg-brand-accent text-brand-accentText px-4 sm:px-6 py-2.5 sm:py-3 
                    text-sm sm:text-base hover:bg-brand-accentHover transition-colors 
                    flex items-center justify-center gap-1.5 sm:gap-2"
                  onClick={() => {
                    handleNavigationLocal("/contact-us");
                    setIsMobileMenuOpen(false);
                  }}
                >
                  LET'S CONNECT
                  <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <FloatingActionButton />
    </nav>
  );
};

export default Navbar;
